/** @format */
:root {
  --bgcblue: #1377be;
  --bgcgreen: #7bba58;
  --maincolor: rgb(255, 255, 255);
  --warningcolor: red;
  --darkcolor: #537b7b;
}

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.navburgeropen,
.navburger {
  text-align: right;
}

.navburgeropen svg,
.navburger svg {
  position: static;
  right: 16px;
  margin: 16px;
  padding: 16px;

  background-color: var(--bgcblue);
  color: white;
  z-index: 1;
  cursor: pointer;
}
svg {
  cursor: pointer;
}

@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
/** @format */

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #e6ca8c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** @format */
:root {
  --bgcblue: #1377be;
  --bgcgreen: #7bba58;
  --maincolor: rgb(255, 255, 255);
  --warningcolor: red;
  --darkcolor: #537b7b;
}

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.navburgeropen,
.navburger {
  text-align: right;
}

.navburgeropen svg,
.navburger svg {
  position: static;
  right: 16px;
  margin: 16px;
  padding: 16px;

  background-color: #1377be;

  background-color: var(--bgcblue);
  color: white;
  z-index: 1;
  cursor: pointer;
}
svg {
  cursor: pointer;
}

